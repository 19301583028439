import React, { useReducer, useEffect, useRef } from "react";
import { Route, Switch, useRouteMatch, useHistory, useLocation  } from "react-router-dom";

import { getListAllAPI, getSessionId, api, getUserId } from "../../services";
import TelaDescarregoItens from "./_SubPages/Itens";
import TelaDescarregoParcelas from "./_SubPages/Parcelas";
import { DescarregoMercadoriaContext } from "./context";
import reducer from "./reducer";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { formatDateDDMMYYYYHHmm, formatMoeda, formatPeso } from "../../utils";

export function formatItens(itens, valorUnitario) {
  const itensFormatados = [];
  for (let i = 0; i < itens.length; i += 1) {
    const total = itens[i].peso * valorUnitario / 1000;
    itensFormatados.push({
      id: itens[i].id,
      peso: itens[i].peso,
      chave: itens[i].nfe.chave,
      data: new Date(itens[i].nfe.dhEmis),
      descarrego: total,
      total: itens[i].nfe.vNF,
    });
  }
  return itensFormatados;
}

function getFornecedor(itens, fornecedor) {
  for (let i = 0; i < itens.length; i += 1) {
    if(itens[i].nfe) {
      if(itens[i].nfe.emitRazao) {
        return itens[i].nfe.emitRazao
      }
    }
  }
  if(fornecedor) {
    return fornecedor.razao_social
  }
  return '-'
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PagarDescarregoMercadoria = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [descarrego, dispatch] = useReducer(reducer, {
    idEdit: -1,
    fornecedor: '-',
    itens: [],
    parcelas: [],
    total: 0,
    valorUnitario: 0,
    peso: 0, 
    data: '-'
  });
  const { path } = useRouteMatch();
  const query = useQuery();

  const descarrego_id = query.get('descarrego_id');

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI("descarrego_mercadorias", ["id", "asc"], { id: [id] }, [
        'fornecedor',
        'itens.nfe'
      ]);
      if (data.data.length > 0) {
        dispatch({ 
          type: "PREENCHER_TUDO", 
          idEdit: id,
          fornecedor: getFornecedor(data.data[0].itens, data.data[0].fornecedor),
          itens: formatItens(data.data[0].itens, data.data[0].valor_unitario),
          parcelas: [],
          total: data.data[0].valor,
          valorUnitario: `${formatMoeda(data.data[0].valor_unitario)} / t`,
          peso: `${formatPeso(data.data[0].peso)} Kg`, 
          data: formatDateDDMMYYYYHHmm(new Date(data.data[0].data))
        });
      } else {
        throw "Este Descarrego de Mercadoria não existe!";
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if(descarrego_id) {
      getDataResource(descarrego_id)
    }
  }, [descarrego_id])

  function irParaTelaInicial() {
    history.push("/");
  }

  function irParaTelaNfes() {
    history.push("/pagar_descarrego/nfes");
  }

  function irParaTelaParcelas() {
    history.push("/pagar_descarrego/parcelas");
  }

  return (
    <>
      {descarrego.fornecedor ? (
        <DescarregoMercadoriaContext.Provider value={{ descarrego, dispatch }}>
          <Switch>
            <Route
              path={`${path}/nfes`}
              render={(props) => (
                <TelaDescarregoItens
                  irParaTelaInicial={irParaTelaInicial}
                  irParaTelaParcelas={irParaTelaParcelas}
                />
              )}
            />
            <Route
              path={`${path}/parcelas`}
              render={(props) => (
                <TelaDescarregoParcelas
                  irParaTelaInicial={irParaTelaInicial}
                  irParaTelaNfes={irParaTelaNfes}
                  getSessionId={() => getSessionId()}
                />
              )}
            />
          </Switch>
        </DescarregoMercadoriaContext.Provider>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontSize: "40px",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          Carregando Descarrego de Mercadoria...
          <Button
            onClick={irParaTelaInicial}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {`Voltar Para a Tela Inicial`}
          </Button>
        </div>
      )}
    </>
  );
};

export default PagarDescarregoMercadoria;
