import React, { useRef, useContext } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";

import { makeStyles, Box } from "@material-ui/core";

import {
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  Tabela,
} from "../../../../components";
import {
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  formatMoeda,
  formatPeso,
  formatDateDDMMYYYYHHmm,
  PRIMARY_COLOR,
  PRIMARY_COLOR_OFFLINE,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE
} from "../../../../utils";
import DialogoAutorizacaoComQrcode from "../../../../components/Dialogos/DialogoAutorizacaoComQrcode";
import { DescarregoMercadoriaContext } from "../../context";
import { useSnackbar } from 'notistack';
import { AppContext } from "../../../../app/context";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: "10px",
    opacity: "0.75",
  },
  header: {
    height: "17%",
  },
  body: {
    height: "83%",
  },
  footer: {
    height: "15vh",
    padding: "10px",
    paddingBottom: "20px",
  },
  headerLivre: {
    height: "17%",
  },
  bodyLivre: {
    height: "83%",
  },
  rootProduto: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    fontSize: "32px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: "center",
    opacity: "0.75",
  },
}));

const AVANCAR_ACTION = ATALHO_AVANCAR;
const VOLTAR_ACTION = ATALHO_VOLTAR;
const CONSULTOR_PRECOS_ACTION = "F3";
const ESC_ACTION = "Escape";

const FrenteVenda = ({ irParaTelaInicial, irParaTelaParcelas }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { app, openConsultorPrecos } = useContext(AppContext);

  const {
    consultarPrecosPermissao,
    tipoCaixa,
    nomeCaixa,
    modoOffline
  } = app;
  const {
    descarrego: {
      itens,
      total,
      fornecedor,
      data,
      peso,
      valorUnitario
    }
  } = useContext(DescarregoMercadoriaContext);

  const refDialogoConsultorPrecos = useRef(null);
  const refDialogoAutorizacaoComQrcode = useRef(null);

  const classes = useStyles();

  const colorHeader =  modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR;

  function handleActions(action) {
    switch (action) {
      case AVANCAR_ACTION:
        irParaTelaParcelas();
        break;
      case VOLTAR_ACTION:
        if (refDialogoAutorizacaoComQrcode.current) {
          refDialogoAutorizacaoComQrcode.current.handleOpen();
        }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case ESC_ACTION:
        if (refDialogoAutorizacaoComQrcode.current) {
          refDialogoAutorizacaoComQrcode.current.handleOpen();
        }
        break;
      default:
        break;
    }
  }

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    if (consultarPrecosPermissao)
      labelsAtalhos.push({
        atalho: CONSULTOR_PRECOS_ACTION,
        label: "Cons. Preço",
      });

    labelsAtalhos.push({ atalho: VOLTAR_ACTION, label: "Voltar" });

    labelsAtalhos.push({ atalho: AVANCAR_ACTION, label: "Concluir" });
  

    if (labelsAtalhos.length <= 2) {
      return [labelsAtalhos, []];
    }

    return [labelsAtalhos.slice(0, 2), labelsAtalhos.slice(2)];
  }

  async function handleCancelar(responsavel_id) {
    irParaTelaInicial();
  }

  function formatarDados(itens) {
    const itensFormatados = [];
    for (let i = 0; i < itens.length; i++) {
      itensFormatados.push({
        id: itens[i].id,
        peso: formatPeso(itens[i].peso),
        chave: itens[i].chave,
        data: formatDateDDMMYYYYHHmm(itens[i].data),
        descarrego: formatMoeda(itens[i].descarrego),
        total: formatMoeda(itens[i].total),
      });
    }
    return itensFormatados;
  }

  return (
    <>
      <Box padding="10px" className={classes.header}>
        <Box
          display="flex"
          css={{
            backgroundColor: 'white', opacity: '0.75', height: '100%', padding: '0px 10px', flexDirection: 'column',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              flex={1}
              style={{
                border: `1px solid ${ modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR}`, marginLeft: '10px', padding: '5px', borderRadius: '5px',
              }}
            >
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Fornecedor:</p>
                  {fornecedor}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Data:</p>
                  {data}
                </Box>
              </Box>
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Peso:</p>
                  {peso}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Descarrego:</p>
                  {valorUnitario}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          overflow="auto"
        >
          <Box>
            <LabelCaixa tipoCaixa={tipoCaixa} nomeCaixa={nomeCaixa} />
            <Box mt="15px">
              <LabelSubtotal valor={total} />
            </Box>
          </Box>
          <LabelTeclasAtalho fontSize="13px" grid={getLabelsAtalhos()} />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={formatarDados(itens)}
            headCells={[
              {
                field: "chave",
                label: "Chave",
              },
              {
                field: "data",
                right: false,
                label: "Data",
              },
              {
                field: "peso",
                right: true,
                label: "Peso (Kg)",
              },
              {
                field: "total",
                right: true,
                label: "Tot. Nfe",
              },
              {
                field: "descarrego",
                right: true,
                label: "Descarrego",
              },
            ]}
          />
        </Box>
      </Box>
      <KeyboardEventHandler
        handleKeys={[
          AVANCAR_ACTION,
          VOLTAR_ACTION,
          CONSULTOR_PRECOS_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoAutorizacaoComQrcode
        ref={refDialogoAutorizacaoComQrcode}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id) => {
          handleCancelar(user_id);
        }}
        mensagemSucesso={"Pagamaneto do Descarrego cancelado com sucesso!"}
        title="Autorização do cancelamento do Pagamento do Descarrego!"
        openConsultorPrecos={openConsultorPrecos}
      />
    </>
  );
};

export default FrenteVenda;
