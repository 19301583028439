import React, {
  useRef,
  useEffect,
  useContext,
} from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { Box, makeStyles } from '@material-ui/core';

import {
  DialogoCliente,
  DialogoConfirmacao,
  DialogoDeletarComSenha,
  DialogoSenha,
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  LabelTotalParcelas,
  Tabela,
  DialogoConsultorPrecos,
  DialogoVerQRCode,
  DialogoSelecionarCliente,
} from '../../../../components';
import LabelTotalParcelasAVista from '../../../../components/Labels/LabelTotalParcelasAVista'
import { useParcelas } from '../../../../hooks';
import {
  getListAllAPI,
  getUserId,
} from '../../../../services';
import {
  formatCPF,
  formatCNPJ,
  toStringParcela,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  PRIMARY_COLOR_OFFLINE,
  SECONDARY_COLOR_OFFLINE
} from '../../../../utils';
import { VendaContext } from '../../context';
import FormParcela from './Form';
import { AppContext } from '../../../../app/context';
import DialogoDeletarItem from '../../../../components/Dialogos/DialogoDeletarItem'
import DialogoAutorizacaoComQrcode from '../../../../components/Dialogos/DialogoAutorizacaoComQrcode'
import DialogoAutorizacaoQrcode2 from '../../../../components/Dialogos/DialogoAutorizacaoComQrcode2'
import DialogoCarregarCreditoDevolucao from './DialogoCarregarCreditoDevolucao'

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const VOLTAR_ACTION = ATALHO_VOLTAR;
const FINALIZAR_ACTION = ATALHO_AVANCAR;
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';

const VENDA_EM_PARTES_ACTION = 'F1';

const SELECIONAR_CLIENTE_ACTION = 'F10';

const CARREGAR_CREDITOS_DEVOLUCOES_ACTION = 'F12';


const ESC_ACTION = 'Escape';

const FinalizarVenda = ({
  irParaTelaInit,
  irParaTelaFrentedeCaixa,
  getSessionId,
}) => {
  const classes = useStyles();
  const { app: { 
    meiosPagamento, 
    contasPixADistancia, 
    modoOffline,
    consultarPrecosPermissao,
    tipoCaixa,
    tipoPagamentoCartaoCreditoPermissao,
    tipoPagamentoCartaoDebitoPermissao,
    tipoPagamentoDevolucaoPermissao,
    tipoPagamentoDinheiroPermissao,
    tipoPagamentoPixDistanciaPermissao,
    tipoPagamentoPixQrcodePermissao,
    tipoPagamentoVoucherPermissao,
    tipoPagamentoCreditoFidelidadePermissao,
    nomeCaixa
  }, openConsultorPrecos } = useContext(AppContext);
  const { venda, dispatch } = useContext(VendaContext);
  const {
    cliente, parcelas, isEmPartes, itensExcluidos, cpf, uuid, idEdit
  } = venda;
  const refSidebarInputs = useRef(null);
  const refDialogoSenha = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);

  const refDialogoCliente = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoConfirmacao = useRef(null);
  const refDialogoLiberarParcela = useRef(null);
  const refDialogoVerQrcode = useRef(null);
  const refDialogoSelecionarCliente = useRef(null);
  const refDialogoDeletarItem = useRef(null);

  const refDialogoAutorizacaoComQrcode2 = useRef(null);

  const refDialogoCarregarCreditoDevolucao = useRef(null)

  const {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    getPodeUsarParcela,
    hasPagamentoNaoPadrao,
    enqueueSnackbar,
    podeFecharVenda,
    descontoUsadoCliente,
    descontoDisponivelCliente,
    totalAVistaCalculado,
    totalAVistaObrigatorio
  } = useParcelas(
    parcelas, venda.itens, cliente, venda.subTotal, getSessionId(), updateParcela, irParaTelaInit, isEmPartes, itensExcluidos, cpf, uuid, modoOffline, idEdit
  );

  useEffect(() => {
    if (refSidebarInputs.current) refSidebarInputs.current.focus();
  }, []);

  function updateParcela(body) {
    dispatch({
      type: 'ADD_PARCELA',
      parcela: body,
    });
  }

  useEffect(() => {
    if (venda.itens.length <= 0) irParaTelaFrentedeCaixa();
  }, [venda.itens]);

  const colorHeader =  modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR;

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_PARCELA', indices });
  }

  function handleActions(action) {
    switch (action) {
      case VOLTAR_ACTION:
        irParaTelaFrentedeCaixa();
        break;
      case FINALIZAR_ACTION:
        if (podeFecharVenda) {
          openConfirmacao();
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (parcelas.length > 0) {
          if (refDialogoDeletarItem.current) { refDialogoDeletarItem.current.handleOpen(); }
        }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case VENDA_EM_PARTES_ACTION:
        if(refDialogoAutorizacaoComQrcode2.current) {
          refDialogoAutorizacaoComQrcode2.current.handleOpen();
        }
        break;
      case ESC_ACTION:
        irParaTelaFrentedeCaixa();
        break;
      case CARREGAR_CREDITOS_DEVOLUCOES_ACTION:
        if(cliente) {
          if(cliente.id !== 1) {
            if(tipoPagamentoDevolucaoPermissao) {
              if(refDialogoCarregarCreditoDevolucao.current) {
                refDialogoCarregarCreditoDevolucao.current.handleOpen()
              }
            }
          } else {
            enqueueSnackbar('O cliente anonimo não pode usar devolução!', {
              variant: 'error',
            });
          }
        } else {
          enqueueSnackbar('É necessário especificar o cliente!', {
            variant: 'error',
          });
        }
        break;
      default:
        break;
    }
  }

  function changeIsInPartes() {
    dispatch({ type: 'CHANGE_IS_EM_PARTES' });
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleFinalizarVenda();
        break;
      default:
        break;
    }
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) { refDialogoConfirmacao.current.handleOpen('Fechamento da venda', 'Tem certeza que deseja finalizar a venda!', 0); }
  }

  async function updateCliente(idUpdate) {
    try {
      const data = await getListAllAPI(
        'clientes',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['cidade.estado', 'categoria'],
      );
      if (data.data.length > 0) {
        dispatch({ type: 'UPDATE_CLIENTE', cliente: data.data[0] });
      } else {
        throw 'Este cliente não existe!';
      }
    } catch (erros) {
      enqueueSnackbar('INTERNET: ERRO AO BUSCAR O CLIENTE!', {
        variant: 'error',
      });
    }
  }

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    labelsAtalhos.push({ atalho: VENDA_EM_PARTES_ACTION, label: 'Tipo Venda' });
    labelsAtalhos.push({ atalho: DELETAR_ITEM_ACTION, label: 'Remover parcela', status: parcelas.length > 0 });
    if (consultarPrecosPermissao) labelsAtalhos.push({ atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' });
    labelsAtalhos.push({ atalho: VOLTAR_ACTION, label: 'Voltar' });
    labelsAtalhos.push({
      atalho: FINALIZAR_ACTION,
      label: 'Concluir',
      status: podeFecharVenda && (!(hasPagamentoDinheiro
        ? !podeEncerrarTransacao : (resto < -0.05 || resto > 0.05))),
      class: (hasPagamentoDinheiro
        ? !podeEncerrarTransacao ? '' : 'btnImprimir' : (resto < -0.05 || resto > 0.05) ? '' : 'btnImprimir'),
    });
    if(tipoPagamentoDevolucaoPermissao) labelsAtalhos.push({ atalho: CARREGAR_CREDITOS_DEVOLUCOES_ACTION, label: 'Car. Descon.' });

    if (labelsAtalhos.length <= 3) {
      return [
        labelsAtalhos,
        [],
      ];
    }
    return [
      labelsAtalhos.slice(0, 3),
      labelsAtalhos.slice(3),
    ];
  }

  function getTipoPagamentoDevolucao() {
    const devolucaoOption = meiosPagamento.find(item => item.id === 9)
    return devolucaoOption
  }

  function handleCarregarCreditoDevolucoes(dados) {
   
    const tipo_pagamento = getTipoPagamentoDevolucao()
    if(tipo_pagamento) {
      const dadosFormatados = []
      for(let i = 0; i < dados.length; i++) {
        dadosFormatados.push({
          data_pagamento: new Date(),
          tipo_pagamento,
          valor: dados[i].valor,
          uidd: `${tipo_pagamento.nome}${parcelas.length}`,
          troco: 0,
          valor_recebido: 0,
          idIndicadorChange: -1,
          autorizador_id: null,
          credito_devolucao_venda_id: dados[i].id
        })
      }
      dispatch({
        type: 'ADD_PARCELAS_DEVOLUCAO',
        parcelas: dadosFormatados,
      });
    }
  }

  return (
    <>
      <Box
        padding="10px"
        className={classes.header}
      >
        <Box
          display="flex"
          css={{
            backgroundColor: 'white', opacity: '0.75', height: '100%', padding: '0px 10px', flexDirection: 'column',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
          >
            {cliente && (
            <Box
              display="flex"
              justifyContent="space-between"
              flex={1}
              style={{
                border: `1px solid ${ modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR}`, marginLeft: '10px', padding: '5px', borderRadius: '5px',
              }}
            >
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Responsável:</p>
                  {cliente.nome}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Razão Social:</p>
                  {cliente.razao_social}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CPF:</p>
                  {formatCPF(cliente.cpf)}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>CNPJ:</p>
                  {formatCNPJ(cliente.cnpj)}
                </Box>
              </Box>
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Nome Fantasia:</p>
                  {cliente.nome_fantasia}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Bairro:</p>
                  {cliente.bairro}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Cidade:</p>
                  {cliente.cidade ? `${cliente.cidade.nome} - ${cliente.cidade.estado.sigla}` : ''}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Logradouro:</p>
                  {cliente.logradouro}
                </Box>
              </Box>
            </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          overflow="auto"
          justifyContent="space-between"
        >
          <Box>
            <LabelCaixa
              descontoDisponivelCliente={descontoDisponivelCliente}
              descontoUsadoCliente={descontoUsadoCliente}
              cliente={cliente}
              tipoCaixa={tipoCaixa}
              isEmPartes={isEmPartes}
              nomeCaixa={nomeCaixa}
            />
            <LabelTotalParcelas total={totalParcelas} totalRestante={resto} />
            {totalAVistaObrigatorio > 0 ? <LabelTotalParcelasAVista totalAVistaCalculado={totalAVistaCalculado} totalAVistaObrigatorio={totalAVistaObrigatorio} /> : null}
            <Box mt="15px">
              <LabelSubtotal valor={venda.subTotal} />
            </Box>
          </Box>
          <LabelTeclasAtalho
            grid={getLabelsAtalhos()}
          />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringParcela(parcelas)}
            headCells={[
              {
                field: 'numeroItem', label: 'Código',
              },
              {
                field: 'tipo_pagamento',
                label: 'Meio de Pagamento',
              },
              {
                field: 'valor', right: true, label: 'Valor',
              },
              {
                field: 'data_pagamento',
                right: true,
                label: 'Data de Pagamento',
              },
              {
                field: 'valor_recebido',
                right: true,
                label: 'Valor Recebido',
              },
              {
                field: 'troco',
                right: true,
                label: 'Troco',
              },
              {
                field: 'conta',
                right: true,
                label: 'Conta',
              },
            ]}
          />
        </Box>
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
        >
          <FormParcela
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            ref={refSidebarInputs}
            handleNewItem={(data) => {
              if (data.tipo_pagamento.id === 9 || data.tipo_pagamento.id === 10) {
                if (refDialogoLiberarParcela.current) {
                  refDialogoLiberarParcela.current.handleOpen(1, data);
                }
              } else {
                handleNewItem(data);
              }
            }}
            resto={resto}
            validateParcela={getPodeUsarParcela}
            hasPagamentoDinheiro={hasPagamentoDinheiro}
            hasPagamentoNaoPadrao={hasPagamentoNaoPadrao}
            parcelas={parcelas}
            cliente={cliente}
            descontoDisponivelCliente={descontoDisponivelCliente}
            descontoUsadoCliente={descontoUsadoCliente}
            tipoPagamentoCartaoCreditoPermissao={tipoPagamentoCartaoCreditoPermissao}
            tipoPagamentoCartaoDebitoPermissao={tipoPagamentoCartaoDebitoPermissao}
            tipoPagamentoDevolucaoPermissao={false}
            tipoPagamentoDinheiroPermissao={tipoPagamentoDinheiroPermissao}
            tipoPagamentoPixDistanciaPermissao={tipoPagamentoPixDistanciaPermissao}
            tipoPagamentoPixQrcodePermissao={tipoPagamentoPixQrcodePermissao}
            tipoPagamentoVoucherPermissao={tipoPagamentoVoucherPermissao}
            tipoPagamentoCreditoFidelidadePermissao={tipoPagamentoCreditoFidelidadePermissao}
            meioPagamentosOptions={meiosPagamento}
            totalAVistaCalculado={totalAVistaCalculado} 
            totalAVistaObrigatorio={totalAVistaObrigatorio}
            contasPixADistanciaOptions={contasPixADistancia}
          />
        </Box>
      </Box>
      <KeyboardEventHandler
        handleKeys={[
          VOLTAR_ACTION,
          FINALIZAR_ACTION,
          DELETAR_ITEM_ACTION,
          VENDA_EM_PARTES_ACTION,
          SELECIONAR_CLIENTE_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoConfirmacao ref={refDialogoConfirmacao} handleConfirma={handleConfirma} isInput openConsultorPrecos={openConsultorPrecos}/>
      <DialogoCliente
        ref={refDialogoCliente}
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateCliente(idUpdate);
          }
        }}
        user_id={getUserId()}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoSenha
        ref={refDialogoSenha}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={() => {
          irParaTelaFrentedeCaixa();
        }}
        mensagemSucesso="Venda liberada para edição"
        title="Autorização voltar à tela de itens!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={parcelas}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([parcelas[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover parcela!"
        mensagemRemovido="Parcela removida com sucesso!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} openConsultorPrecos={openConsultorPrecos}/>
      <DialogoAutorizacaoComQrcode
        ref={refDialogoLiberarParcela}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id, data) => {
          handleNewItem(data, user_id)
        }}
        //mensagemSucesso='Desconto liberado com sucesso!'
        //title="Autorização Desconto!"
        mensagemSucesso='Confirmação do Pix a Distância com sucesso!'
        title="Autorização Pix a Distância!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoDeletarItem
        ref={refDialogoDeletarItem}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(codigo) => {
          const position = parseInt(codigo, 10) - 1
          if(position <= parcelas.length - 1) {
            removeItens([parcelas[position].uidd]);
            enqueueSnackbar('Parcela removida com sucesso!', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Não existe uma parcela com este código!', {
              variant: 'error',
            });
          }
        }}
        title="Remover Item!"
        mensagemRemovido="Item Removido com Sucesso!"
        titleAutorizacao="Autorizar remoção de item"
        mensagemRemovidoAutorizacao="Autorizada a remoção do item!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoVerQRCode
        ref={refDialogoVerQrcode}
        handleClose={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoSelecionarCliente
        handleSelecionado={() => {}}
        handleTerminado={() => {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.focus();
          }
        }}
        handleFechado={(cliente) => {
          if (refSidebarInputs.current) {
            refSidebarInputs.current.focus();
          }
          if(cliente) {
            dispatch({ type: 'UPDATE_CLIENTE', cliente });
          }
        }}
        dispatch={dispatch}
        ref={refDialogoSelecionarCliente}
        clienteInicial={cliente}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoAutorizacaoQrcode2
        ref={refDialogoAutorizacaoComQrcode2} 
        handleSalvar={() => {
          changeIsInPartes();
        }}
        enqueueSnackbar={enqueueSnackbar}
        mensagemSucesso={isEmPartes ? 'Venda alterada para Venda Comum' : 'Venda alterada para Venda em partes'}
        title={isEmPartes ? 'Mudar para venda comum' : 'Mudar para venda em partes'}
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoCarregarCreditoDevolucao
        ref={refDialogoCarregarCreditoDevolucao}
        handleCarregarCreditoDevolucoes={handleCarregarCreditoDevolucoes} 
        cliente={cliente}
        openConsultorPrecos={openConsultorPrecos}
      />
    </>
  );
};

export default FinalizarVenda;
