import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useContext
} from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, createStyles
} from '@material-ui/core';

import {
  TextInput,
} from '../../../components';
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE
} from '../../../utils';

import { AppContext } from '../../../app/context';

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
const ESC_ACTION = 'Escape';

const useStyles = makeStyles((theme) => createStyles({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  disabledButton: {
    backgroundColor: 'red'
  }
}));

const DialogoPagarDescarregoMercadoria = forwardRef(({
  handleSalvar,
}, ref) => {
  const [open, setOpen] = useState(false);
  const [codigo, setCodigo] = useState('');
  const classes = useStyles();
  const { app: { modoOffline } } = useContext(AppContext);

  function handleCloseLocal() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigo('');
    },
  }));

  function salvarLocal() {
    setOpen(false);
  }

  useEffect(() => {
    if(!open && codigo !== '') {
      handleSalvar(codigo);
      setCodigo('');
    }
  }, [open])

  function handleActions(action) {
    switch (action) {
      case FECHAR_VENDA_ACTION:
        salvarLocal();
        break;
      case CANCELAR_VENDA_ACTION:
        setCodigo('');
        setOpen(false);
        break;
      case ESC_ACTION:
        setCodigo('');
        setOpen(false);
        break;
      default:
        break;
    }
  }

  function validateCodigo() {
    if (codigo.length !== 16) {
      return 'Este campo deve conter 16 dígitos!';
    }
    return '';
  }

  const erroCodigo = validateCodigo();

  function limparCodigo(codigo) {
    if (codigo) {
      const codigoSemEspacos = codigo.trim();
      return codigoSemEspacos/* .replace(/\D/g, '') */;
    }
    return '';
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle 
          id="alert-dialog-title" 
          style={{ 
            color: 'white', 
            backgroundColor: modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR, 
            textAlign: 'center' 
          }}
        >
          CARREGAR DESCARREGO DE MERCADORIA
        </DialogTitle>
        <DialogContent>
          <TextInput
            name="codigo"
            handleEnter={salvarLocal}
            label=""
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            value={codigo}
            onChange={(value) => setCodigo(limparCodigo(value.target.value))}
            fullWidth
            autoFocus
            style={{
              marginTop: '10px',
              backgroundColor: 'white',
            }}
            error={erroCodigo !== ''}
            helperText={erroCodigo}
          />
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button 
            onClick={handleCloseLocal} 
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
          color="secondary"
          variant="contained"
            onClick={salvarLocal}
            tabindex="-1"
            disabled={erroCodigo !== ''}
          >
            {`Carregar (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoPagarDescarregoMercadoria;
