export function writeCodigoBarras(id) {
  const idString = `${id}`;
  let codigoEN13 = '789';
  for (let i = 0; i < 10 - idString.length; i += 1) {
    codigoEN13 = `${codigoEN13}0`;
  }
  codigoEN13 = `${codigoEN13}${idString}`;
  return codigoEN13;
}

export function readCodigoBarras(codigo) {
  return parseInt(codigo.substring(3), 10);
}

export function writeCodigoBarrasCDM(id) {
  const idString = `${id}`;
  let codigoEN13 = '0CFCDM';
  for (let i = 0; i < 10 - idString.length; i += 1) {
    codigoEN13 = `${codigoEN13}0`;
  }
  codigoEN13 = `${codigoEN13}${idString}`;
  return codigoEN13;
}

export function readCodigoBarrasCDM(codigo) {
  return parseInt(codigo.substring(6), 10);
}
