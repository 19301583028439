
function renameParcelasUIDD(parcelas) {
  const arrayNew = parcelas.slice();
  for (let i = 0; i < parcelas.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].tipo_pagamento.nome}${i}`;
  }
  return arrayNew;
}

function renameItensUIDD(itens) {
  const arrayNew = itens.slice();
  for (let i = 0; i < itens.length; i += 1) {
    arrayNew[i].uidd = `${arrayNew[i].chave}${i}`;
  }
  return arrayNew;
}

const reducer = (state, action) => {
  let arrayNewParcelas = state.parcelas.slice();
  switch (action.type) {
    case 'ADD_PARCELA': {
      const dados = {
        ...state,
        parcelas: [...arrayNewParcelas, action.parcela],
      };
      return dados
    }
    case 'REMOVE_PARCELA': {
      for (let i = 0; i < action.indices.length; i += 1) {
        arrayNewParcelas = arrayNewParcelas.filter(
          (obj) => obj.uidd !== action.indices[i],
        );
      }
      const dados = {
        ...state,
        parcelas: [...renameParcelasUIDD(arrayNewParcelas)],
      };
      return dados
    }
    case 'RESETAR_TUDO': {
      const dados = {
        ...state,
        idEdit: -1,
        fornecedor: '-',
        itens: [],
        parcelas: [],
        total: 0,
        valorUnitario: 0,
        peso: 0, 
        data: '-'
      };

      return dados
    }
    case 'PREENCHER_TUDO': {
      const dados = {
        ...state,
        idEdit: action.idEdit,
        itens: [...renameItensUIDD(action.itens)],
        total: action.total,
        parcelas: [],
        fornecedor: action.fornecedor,
        valorUnitario: action.valorUnitario,
        peso: action.peso, 
        data: action.data
      };

      return dados
    }
    default:
      return state;
  }
};

export default reducer;
