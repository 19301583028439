import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useSnackbar } from 'notistack';

import { api, getUserId } from '../services';
import {
  A_VISTA,
} from '../utils';

const idTiposPagamentoRepetidos = [3, 4, 5, 7, 8, 9, 10, 11];

// 1 - dinheiro
// 2 - fiado
// 3 - crédito
// 4 - débito
// 5 - pix qrcode
// 6 - Boleto
// 7 - pix - banco do brasil
// 8 - voucher
// 9 - devolucao
// 10 - pix a distancia
// 11 - credito fidelidade

function useParcelasVenda(
  id,
  parcelas,
  subtotal,
  sessionId,
  updateParcela,
  irParaTelaInit,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [podeFecharVenda, setPodeFecharVenda] = useState(true);
  const controle = useRef({ status: true })

  function parcelaInclusa(id) {
    for (let i = 0; i < idTiposPagamentoRepetidos.length; i += 1) {
      if (id === idTiposPagamentoRepetidos[i]) return true;
    }
    return false;
  }

  function getProcuraPagamentoDinheiro() {
    for (let i = 0; i < parcelas.length; i += 1) {
      if (parcelas[i].tipo_pagamento.id === 1) return true;
    }
    return false;
  }

  function getPodeEncerrarTransacao(resto) {
    if (resto >= -2 && resto <= 2) return true;
    return false;
  }

  function getValorRestante() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return subtotal - soma;
  }

  function getTotalParcelas() {
    let soma = 0;
    for (let i = 0; i < parcelas.length; i += 1) {
      soma += parcelas[i].valor;
    }
    return soma;
  }

  const resto = getValorRestante();
  const podeEncerrarTransacao = getPodeEncerrarTransacao(resto);
  const hasPagamentoDinheiro = getProcuraPagamentoDinheiro();
  const totalParcelas = getTotalParcelas();

  function getMessagesError() {
    const errors = [];
    if (parcelas.length <= 0) {
      errors.push({
        message: 'É necessário ao menos um registro de parcela!',
        type: 'warning',
      });
    }
    const erroDistribuicao = hasPagamentoDinheiro
      ? !podeEncerrarTransacao : (resto < -0.05 || resto > 0.05);
    if (erroDistribuicao) {
      errors.push({
        message: 'O valor total do descarrego não foi integralmente distribuído nas parcelas!',
        type: 'warning',
      });
    }

    return errors;
  }

  useEffect(() => {
    if (getMessagesError().length === 0) {
      enqueueSnackbar(
        'Agora você pode finalizar o Pagamento do Descarrego!!!',
        {
          variant: 'success',
        },
      );
    }
  }, [parcelas]);

  function montarObjVenda() {
    const listaParcelas = [];
    for (let i = 0; i < parcelas.length; i += 1) {
      listaParcelas.push({
        tipo_pagamento_id: parcelas[i].tipo_pagamento.id,
        data_pagamento: parcelas[i].data_pagamento,
        valor: parcelas[i].valor,
        data_pagamento_real:
                parcelas[i].tipo_pagamento.modalidade === A_VISTA ? new Date() : null,
        valor_recebido:
          (parcelas[i].tipo_pagamento.dinheiro_vivo
            && parcelas[i].tipo_pagamento.modalidade === A_VISTA
          )
            ? parcelas[i].valor_recebido : 0,
        autorizador_id: parcelas[i].autorizador_id ? parcelas[i].autorizador_id : null,
        conta_id: parcelas[i].conta_id ? parcelas[i].conta_id : null,
      });
    }

    return {
      parcelas: listaParcelas,
      sessao_id: sessionId,
      userId: getUserId()
    };
  }

  async function submitVenda() {
    try {
      const objVenda = montarObjVenda();
      let response = null
      response = await api.post(`/descarrego_mercadorias/pagar/${id}`, {
        ...objVenda,
      });
      const data = response.data
      if(data.status) {
        return {
          id: data.data.id,
          message: ''
        }
      }
      throw data.message
    } catch (e) {
      return {
        id: -1,
        message: 'ERRO NO REGISTRO DA NOTA'
      };
    }
  }

  async function handleFinalizarVenda() {
    const errors = getMessagesError();
    if (errors.length <= 0 && controle.current.status) {
      controle.current.status = false
      setPodeFecharVenda(false);
      const responseVenda = await submitVenda();
      if (responseVenda.id > 0) {
        irParaTelaInit();
      } else {
        controle.current.status = true
        setPodeFecharVenda(true);
        enqueueSnackbar(
          responseVenda.message,
          {
            variant: 'error',
          },
        );
      }
    } else {
      for (let i = 0; i < errors.length; i += 1) {
        enqueueSnackbar(
          errors[i].message,
          {
            variant: errors[i].type,
          },
        );
      }
    }
  }

  function handleNewItem({
    valor,
    tipo_pagamento,
    data_pagamento,
    troco,
    valor_recebido,
    conta_id,
    conta
  }, autorizador_id) {
      updateParcela({
        data_pagamento,
        tipo_pagamento,
        valor,
        uidd: `${tipo_pagamento.nome}${parcelas.length}`,
        troco,
        valor_recebido,
        idIndicadorChange: -1,
        autorizador_id,
        conta_id,
        conta
      });
  }

  function getPodeUsarParcela(id) {
    const idInclusoRepetidos = parcelaInclusa(id);

    if (idInclusoRepetidos) {
      return {
        status: true,
        message: '',
      };
    }
    if (id === 1) {
      if (hasPagamentoDinheiro) {
        return {
          status: false,
          message: 'Já existem uma parcela em dinheiro registrada!',
        };
      }
      return {
        status: true,
        message: '',
      };
    }
    return {
      status: true,
      message: '',
    };
  }

  

  return {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    enqueueSnackbar,
    getPodeUsarParcela,
    podeFecharVenda,
  };
}

export default useParcelasVenda;
