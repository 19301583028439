import {
  createContext,
} from 'react';

export const AppContext = createContext({
  app: {
    saldoCaixa: 0,
    mensagemInicialFc: '',
    saldoIndicadorRetirada: 1500,
    meiosPagamento: [],
    limiteInferiorNotaCpf: 5000,
    limiteSuperiorNotaCpf: 10000,
    videosTutoriais: [],
    contasPixADistancia: [],
    modoOffline: false,
    isBuscouDadosConfig: false,
    valorMaximoPesoNoFc: 25,
    //////////////////
    criarVendaPermissao: false,
    consultarPrecosPermissao: false,
    utilizarTrocoPermissao: false,
    utilizarTrocoAberturaCaixaPermissao: false,
    criarPedidoPermissao: false,
    criarRetiradaPermissao: false,
    carregarPedidoPermissao: false,
    criarClientePermissao: false,
    editarClientePermissao: false,
    tipoPagamentoDinheiroPermissao: false,
    tipoPagamentoCartaoCreditoPermissao: false,
    tipoPagamentoCartaoDebitoPermissao: false,
    tipoPagamentoPixQrcodePermissao: false,
    tipoPagamentoVoucherPermissao: false,
    tipoPagamentoDevolucaoPermissao: false,
    tipoPagamentoPixDistanciaPermissao: false,
    tipoPagamentoCreditoFidelidadePermissao: false,
    pesquisaPorNomes: false,
    tipoCaixa: '',
    nomeCaixa: '',
    isBuscouDadosPermissoes: false,
    //
    pesquisaPorCodigoDeBalancaPermissao: false,
    conferenciaGrandesPesagensPermissao: false,
    pagarDescarregoMercadoriaPermissao: false
  },
  dispatch: (data) => {},
  openConsultorPrecos: () => {},
});
