import React, {
    useImperativeHandle,
    forwardRef,
    useState,
    useRef,
  } from 'react';
  
  import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
  } from '@material-ui/core';
  
  import {
    IntegerInput,
  } from '../../../components';
  import {
    ATALHO_VOLTAR,
    ATALHO_AVANCAR,
    SECONDARY_COLOR,
    SECONDARY_COLOR_OFFLINE
  } from '../../../utils';
  
  const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
  const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;
  const ESC_ACTION = 'Escape';
  
  const DialogoConfirmacao = forwardRef(({
    handleVendaId = () => {}
  }, ref) => {
    const [open, setOpen] = useState(false);
    const [vendaId, setVendaId] = useState(Number.NaN);

    const refDialogoSenha = useRef(null)
  
    function handleCloseLocal() {
      setOpen(false);
    }
  
    useImperativeHandle(ref, () => ({
      handleOpen() {
        setOpen(true);
        setVendaId(Number.NaN)
      },
    }));
  
    function handleActions(action) {
      switch (action) {
        case CANCELAR_VENDA_ACTION:
            handleCloseLocal()
            break;
        case ESC_ACTION:
          handleCloseLocal()
            break;
        case FECHAR_VENDA_ACTION:
          if(!error) {
            handleVendaId(vendaId);
            handleCloseLocal()
          }
          break;
          
        default:
            break;
      }
    }

    function validateInteger() {
      let error = '';
        if (vendaId <= 0 || Number.isNaN(vendaId)) {
          error = 'Este campo é obrigatório';
        }
      return error;
    }

    const error = validateInteger()
  
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseLocal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle 
            id="alert-dialog-title" 
            style={{ 
              color: 'white', 
              backgroundColor: SECONDARY_COLOR, 
              textAlign: 'center' 
            }}
          >
            ID DA VENDA
          </DialogTitle>
          <DialogContent>
            <IntegerInput
              name="sessao"
              handleEnter={() => {
                if(error === '') {
                  if(refDialogoSenha.current) {
                    refDialogoSenha.current.handleOpen()
                  }
                }
              }}
              label="Id da Venda"
              handleKey={(keyCode, keyName) => {
                handleActions(keyName);
              }}
              value={Number.isNaN(vendaId) ? '' : String(vendaId)}
              onChange={(value) => setVendaId(parseInt(value.target.value, 10))}
              style={{
                marginTop: '10px',
                backgroundColor: 'white',
              }}
              error={error !== ''}
              helperText={error}
              fullWidth
              autoFocus
            />
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => handleActions(CANCELAR_VENDA_ACTION)} color="secondary" variant="contained">
              {`Cancelar (${CANCELAR_VENDA_ACTION})`}
            </Button>
            <Button onClick={() => handleActions(FECHAR_VENDA_ACTION)} color="secondary" variant="contained" disabled={error !== ''}>
              {`Reconectar (${FECHAR_VENDA_ACTION})`}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  });
  
  export default DialogoConfirmacao;
  