import {
  useState,
  useEffect,
} from 'react';

import { useSnackbar } from 'notistack';

import { api, getSessionId } from '../services';

function usePermissoes() {
  const [permissoes, setPermissoes] = useState(null);
  const [contadorPermissoes, setContadorPermissoes] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  async function getPermissoes() {
    try {
      const response = await api.get(`permissoes_frente_caixa/${getSessionId()}`);
      setPermissoes(response.data);
      setContadorPermissoes(contadorPermissoes + 1);
    } catch (e) {
      setPermissoes(null);
      enqueueSnackbar(
        'INTERNET: ERRO AO OBTER AS PERMISSÕES!',
        {
          variant: 'error',
        },
      );
    }
  }

  function formatPermissoes() {
    const permissoesInit = {
      criarVendaPermissao: false,
      consultarPrecosPermissao: false,
      utilizarTrocoPermissao: false,
      utilizarTrocoAberturaCaixaPermissao: false,
      criarPedidoPermissao: false,
      criarRetiradaPermissao: false,
      carregarPedidoPermissao: false,
      criarClientePermissao: false,
      editarClientePermissao: false,
      tipoPagamentoDinheiroPermissao: false,
      tipoPagamentoCartaoCreditoPermissao: false,
      tipoPagamentoCartaoDebitoPermissao: false,
      tipoPagamentoPixQrcodePermissao: false,
      tipoPagamentoVoucherPermissao: false,
      tipoPagamentoDevolucaoPermissao: false,
      tipoPagamentoPixDistanciaPermissao: false,
      tipoPagamentoCreditoFidelidadePermissao: false,
      pesquisaPorNomes: false,
      tipoCaixa: '',
      pagarDescarregoMercadoriaPermissao: false
    };

    if (permissoes) {
      permissoesInit.criarVendaPermissao = permissoes.criar_venda;
      permissoesInit.consultarPrecosPermissao = permissoes.consultar_precos;
      permissoesInit.utilizarTrocoPermissao = permissoes.utilizar_troco;
      permissoesInit.utilizarTrocoAberturaCaixaPermissao = permissoes.utilizar_troco_abertura_caixa;
      permissoesInit.criarPedidoPermissao = permissoes.criar_pedido;
      permissoesInit.criarRetiradaPermissao = permissoes.criar_retirada;
      permissoesInit.carregarPedidoPermissao = permissoes.carregar_pedido;
      permissoesInit.criarClientePermissao = permissoes.criar_cliente;
      permissoesInit.editarClientePermissao = permissoes.editar_cliente;
      permissoesInit.tipoPagamentoDinheiroPermissao = permissoes.tipo_pagamento_dinheiro;
      permissoesInit.tipoPagamentoCartaoCreditoPermissao = permissoes.tipo_pagamento_cartao_credito;
      permissoesInit.tipoPagamentoCartaoDebitoPermissao = permissoes.tipo_pagamento_cartao_debito;
      permissoesInit.tipoPagamentoPixQrcodePermissao = permissoes.tipo_pagamento_pix_qrcode;
      permissoesInit.tipoPagamentoVoucherPermissao = permissoes.tipo_pagamento_voucher;
      permissoesInit.tipoPagamentoDevolucaoPermissao = permissoes.tipo_pagamento_devolucao;
      permissoesInit.tipoPagamentoPixDistanciaPermissao = permissoes.tipo_pagamento_pix_distancia;
      permissoesInit.tipoPagamentoCreditoFidelidadePermissao = permissoes.tipo_pagamento_credito_fidelidade;
      permissoesInit.pesquisaPorNomes = permissoes.pesquisa_por_nomes;
      permissoesInit.tipoCaixa = permissoes.nome;
      permissoesInit.pagarDescarregoMercadoriaPermissao = permissoes.pagar_descarrego_mercadoria;
    }

    return permissoesInit;
  }

  useEffect(() => {
    getPermissoes();
  }, []);

  function updatePermissoes() {
    getPermissoes();
  }

  return {
    ...formatPermissoes(),
    updatePermissoes,
    contadorPermissoes,
  };
}

export default usePermissoes;
