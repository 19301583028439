import React, {
  useRef,
  useEffect,
  useContext,
} from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { Box, makeStyles } from '@material-ui/core';

import {
  DialogoConfirmacao,
  LabelCaixa,
  LabelSubtotal,
  LabelTeclasAtalho,
  LabelTotalParcelas,
  Tabela,
  DialogoConsultorPrecos,
} from '../../../../components';
import { useParcelasDescarrego } from '../../../../hooks';
import {
  toStringParcela,
  ATALHO_AVANCAR,
  ATALHO_VOLTAR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_OFFLINE,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE
} from '../../../../utils';
import { DescarregoMercadoriaContext } from '../../context';
import FormParcela from './Form';
import { AppContext } from '../../../../app/context';
import DialogoDeletarItem from '../../../../components/Dialogos/DialogoDeletarItem'
import DialogoAutorizacaoComQrcode from '../../../../components/Dialogos/DialogoAutorizacaoComQrcode'

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {},
  btnImprimir: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.4,
  },
  header: {
    height: '17%',
  },
  body: {
    height: '83%',
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const VOLTAR_ACTION = ATALHO_VOLTAR;
const FINALIZAR_ACTION = ATALHO_AVANCAR;
const DELETAR_ITEM_ACTION = 'F2';
const CONSULTOR_PRECOS_ACTION = 'F3';

const ESC_ACTION = 'Escape';

const FinalizarVenda = ({
  irParaTelaInicial,
  irParaTelaNfes,
  getSessionId,
}) => {
  const classes = useStyles();
  const { app: { 
    meiosPagamento, 
    contasPixADistancia, 
    consultarPrecosPermissao,
    tipoCaixa,
    tipoPagamentoCartaoCreditoPermissao,
    tipoPagamentoCartaoDebitoPermissao,
    tipoPagamentoDinheiroPermissao,
    tipoPagamentoPixDistanciaPermissao,
    tipoPagamentoPixQrcodePermissao,
    tipoPagamentoVoucherPermissao,
    nomeCaixa,
    modoOffline
  }, openConsultorPrecos } = useContext(AppContext);
  const { descarrego, dispatch } = useContext(DescarregoMercadoriaContext);
  const {
    parcelas,
    total,
    idEdit,
    fornecedor,
    data,
    peso,
    valorUnitario
  } = descarrego;


  const refSidebarInputs = useRef(null);
  const refDialogoConsultorPrecos = useRef(null);

  const refDialogoConfirmacao = useRef(null);
  const refDialogoLiberarParcela = useRef(null);
  const refDialogoDeletarItem = useRef(null);

   const colorHeader =  modoOffline ? SECONDARY_COLOR_OFFLINE : SECONDARY_COLOR;

  const {
    resto,
    podeEncerrarTransacao,
    hasPagamentoDinheiro,
    totalParcelas,
    handleFinalizarVenda,
    handleNewItem,
    getPodeUsarParcela,
    enqueueSnackbar,
    podeFecharVenda,
  } = useParcelasDescarrego(
    idEdit, parcelas, total, getSessionId(), updateParcela, irParaTelaInicial,
  );

  useEffect(() => {
    if (refSidebarInputs.current) refSidebarInputs.current.focus();
  }, []);

  function updateParcela(body) {
    dispatch({
      type: 'ADD_PARCELA',
      parcela: body,
    });
  }

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_PARCELA', indices });
  }

  function handleActions(action) {
    switch (action) {
      case VOLTAR_ACTION:
        irParaTelaNfes();
        break;
      case FINALIZAR_ACTION:
        if (podeFecharVenda) {
          openConfirmacao();
        }
        break;
      case DELETAR_ITEM_ACTION:
        if (parcelas.length > 0) {
          if (refDialogoDeletarItem.current) { refDialogoDeletarItem.current.handleOpen(); }
        }
        break;
      case CONSULTOR_PRECOS_ACTION:
        if (consultarPrecosPermissao) {
          if (refDialogoConsultorPrecos.current) {
            refDialogoConsultorPrecos.current.handleOpen();
          }
        }
        break;
      case ESC_ACTION:
        irParaTelaNfes();
        break;
      default:
        break;
    }
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case 0:
        handleFinalizarVenda();
        break;
      default:
        break;
    }
  }

  function openConfirmacao() {
    if (refDialogoConfirmacao.current) { refDialogoConfirmacao.current.handleOpen('Pagamento do Descarrego', 'Tem certeza que deseja finalizar o Pagamento do Descarrego!', 0); }
  }

  function getLabelsAtalhos() {
    const labelsAtalhos = [];

    labelsAtalhos.push({ atalho: DELETAR_ITEM_ACTION, label: 'Remover parcela', status: parcelas.length > 0 });
    if (consultarPrecosPermissao) labelsAtalhos.push({ atalho: CONSULTOR_PRECOS_ACTION, label: 'Cons. Preço' });
    labelsAtalhos.push({ atalho: VOLTAR_ACTION, label: 'Voltar' });
    labelsAtalhos.push({
      atalho: FINALIZAR_ACTION,
      label: 'Concluir',
      status: podeFecharVenda && (!(hasPagamentoDinheiro
        ? !podeEncerrarTransacao : (resto < -0.05 || resto > 0.05))),
      class: (hasPagamentoDinheiro
        ? !podeEncerrarTransacao ? '' : 'btnImprimir' : (resto < -0.05 || resto > 0.05) ? '' : 'btnImprimir'),
    });

    if (labelsAtalhos.length <= 2) {
      return [
        labelsAtalhos,
        [],
      ];
    }
    return [
      labelsAtalhos.slice(0, 2),
      labelsAtalhos.slice(2),
    ];
  }

  return (
    <>
     <Box padding="10px" className={classes.header}>
        <Box
          display="flex"
          css={{
            backgroundColor: 'white', opacity: '0.75', height: '100%', padding: '0px 10px', flexDirection: 'column',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flex={1}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              flex={1}
              style={{
                border: `1px solid ${ modoOffline ? PRIMARY_COLOR_OFFLINE : PRIMARY_COLOR}`, marginLeft: '10px', padding: '5px', borderRadius: '5px',
              }}
            >
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Fornecedor:</p>
                  {fornecedor}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1}>
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Data:</p>
                  {data}
                </Box>
              </Box>
              <Box marginLeft="10px" display="flex" flexDirection="column" flex={1}>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Peso:</p>
                  {peso}
                </Box>
                <Box marginLeft="0px" fontSize="15px" fontWeight="bold" display="flex" flex={1} justifyContent="flex-start">
                  <p className={classes.textOverflow} style={{ marginBottom: '0px', color: colorHeader, marginRight: '10px' }}>Descarrego:</p>
                  {valorUnitario}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
          overflow="auto"
          justifyContent="space-between"
        >
          <Box>
            <LabelCaixa
              tipoCaixa={tipoCaixa}
              nomeCaixa={nomeCaixa}
            />
            <LabelTotalParcelas total={totalParcelas} totalRestante={resto} />
            <Box mt="15px">
              <LabelSubtotal valor={total} />
            </Box>
          </Box>
          <LabelTeclasAtalho
            grid={getLabelsAtalhos()}
          />
        </Box>
        <Box padding="0 10px" flex={4}>
          <Tabela
            rows={toStringParcela(parcelas)}
            headCells={[
              {
                field: 'numeroItem', label: 'Código',
              },
              {
                field: 'tipo_pagamento',
                label: 'Meio de Pagamento',
              },
              {
                field: 'valor', right: true, label: 'Valor',
              },
              {
                field: 'data_pagamento',
                right: true,
                label: 'Data de Pagamento',
              },
              {
                field: 'valor_recebido',
                right: true,
                label: 'Valor Recebido',
              },
              {
                field: 'troco',
                right: true,
                label: 'Troco',
              },
              {
                field: 'conta',
                right: true,
                label: 'Conta',
              },
            ]}
          />
        </Box>
        <Box
          flex={1.5}
          display="flex"
          flexDirection="column"
        >
          <FormParcela
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            ref={refSidebarInputs}
            handleNewItem={(data) => {
              if (data.tipo_pagamento.id === 9 || data.tipo_pagamento.id === 10) {
                if (refDialogoLiberarParcela.current) {
                  refDialogoLiberarParcela.current.handleOpen(1, data);
                }
              } else {
                handleNewItem(data);
              }
            }}
            resto={resto}
            validateParcela={getPodeUsarParcela}
            hasPagamentoDinheiro={hasPagamentoDinheiro}
            parcelas={parcelas}
            tipoPagamentoCartaoCreditoPermissao={tipoPagamentoCartaoCreditoPermissao}
            tipoPagamentoCartaoDebitoPermissao={tipoPagamentoCartaoDebitoPermissao}
            tipoPagamentoDinheiroPermissao={tipoPagamentoDinheiroPermissao}
            tipoPagamentoPixDistanciaPermissao={tipoPagamentoPixDistanciaPermissao}
            tipoPagamentoPixQrcodePermissao={tipoPagamentoPixQrcodePermissao}
            tipoPagamentoVoucherPermissao={tipoPagamentoVoucherPermissao}
            meioPagamentosOptions={meiosPagamento}
            contasPixADistanciaOptions={contasPixADistancia}
          />
        </Box>
      </Box>
      <KeyboardEventHandler
        handleKeys={[
          VOLTAR_ACTION,
          FINALIZAR_ACTION,
          DELETAR_ITEM_ACTION,
        ]}
        onKeyEvent={(key) => handleActions(key)}
      />
      <DialogoConfirmacao ref={refDialogoConfirmacao} handleConfirma={handleConfirma} isInput openConsultorPrecos={openConsultorPrecos}/>
      <DialogoConsultorPrecos ref={refDialogoConsultorPrecos} openConsultorPrecos={openConsultorPrecos}/>
      <DialogoAutorizacaoComQrcode
        ref={refDialogoLiberarParcela}
        handleClose={() => {}}
        enqueueSnackbar={enqueueSnackbar}
        handleSalvar={(user_id, data) => {
          handleNewItem(data, user_id)
        }}
        mensagemSucesso='Confirmação do Pix a Distância com sucesso!'
        title="Autorização Pix a Distância!"
        openConsultorPrecos={openConsultorPrecos}
      />
      <DialogoDeletarItem
        ref={refDialogoDeletarItem}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(codigo) => {
          const position = parseInt(codigo, 10) - 1
          if(position <= parcelas.length - 1) {
            removeItens([parcelas[position].uidd]);
            enqueueSnackbar('Parcela removida com sucesso!', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Não existe uma parcela com este código!', {
              variant: 'error',
            });
          }
        }}
        title="Remover Item!"
        mensagemRemovido="Item Removido com Sucesso!"
        titleAutorizacao="Autorizar remoção de item"
        mensagemRemovidoAutorizacao="Autorizada a remoção do item!"
        openConsultorPrecos={openConsultorPrecos}
      />
    </>
  );
};

export default FinalizarVenda;
