import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
  useContext,
} from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  createStyles,
} from "@material-ui/core";

import {
  CpfInput,
  DialogoConfirmacao,
  IntegerInput,
  PrecoInput,
} from "../../../../components";
import {
  ATALHO_VOLTAR,
  ATALHO_AVANCAR,
  SECONDARY_COLOR,
  SECONDARY_COLOR_OFFLINE,
} from "../../../../utils";

import { AppContext } from "../../../../app/context";

const CANCELAR_VENDA_ACTION = ATALHO_VOLTAR;
const FECHAR_VENDA_ACTION = ATALHO_AVANCAR;

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
    disabledButton: {
      backgroundColor: "red",
    },
  })
);

const DialogoCpfNota = forwardRef(({ handleSalvar = () => {}, itens }, ref) => {
  const [open, setOpen] = useState(false);
  const [codigoItem, setCodigoItem] = useState(0);
  const [unitario, setUnitario] = useState(0);
  const classes = useStyles();
  const {
    app: { modoOffline },
  } = useContext(AppContext);

  function handleClose() {
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
      setCodigoItem(0);
      setUnitario(0)
    },
  }));

  let index = -1
  let item = null

  if (codigoItem <= 0 || Number.isNaN(codigoItem)) {
    
  } else {
    if(codigoItem <= itens.length) {
      index = itens.length - codigoItem
      item = itens[index]
    }
  }

  function handleSalvarLocal() {
    setOpen(false);
    handleSalvar({
        index,
        valor: unitario
    })
  }

  function validateInteger() {
    let error = "";
    if (codigoItem <= 0 || Number.isNaN(codigoItem)) {
      error = "Este campo é obrigatório";
    } else {
      if(codigoItem > itens.length) {
        error = "Este código não existe na tabela";
      }
    }
    return error;
  }

  const errorCodigoItem = validateInteger();

  function validateUnitario() {
    let error = '';
    if (unitario <= 0 || Number.isNaN(unitario)) {
      error = 'Este campo é obrigatório';
    } 
    return error;
  }

  const errorUnitario = validateUnitario();

  const isError = errorCodigoItem !== '' || errorUnitario !== ''

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "white",
            backgroundColor: modoOffline
              ? SECONDARY_COLOR_OFFLINE
              : SECONDARY_COLOR,
            textAlign: "center",
          }}
        >
          MUDAR O PREÇO DO ITEM
        </DialogTitle>
        <DialogContent
          style={{
            padding: "20px 10px",
          }}
        >
          <IntegerInput
            name="codigo"
            handleEnter={() => {}}
            label="Código do Item"
            handleKey={(keyCode, keyName) => {}}
            value={Number.isNaN(codigoItem) ? "" : String(codigoItem)}
            onChange={(value) =>
              setCodigoItem(parseInt(value.target.value, 10))
            }
            style={{
              marginTop: "10px",
              backgroundColor: "white",
            }}
            error={errorCodigoItem !== ""}
            helperText={errorCodigoItem}
            fullWidth
            autoFocus
          />
          <PrecoInput
            name="preço"
            handleEnter={() => {}}
            label="Novo Preço"
            handleKey={(keyCode, keyName) => {}}
            value={Number.isNaN(unitario) ? "" : String(unitario)}
            onChange={(value) =>
              setUnitario(parseFloat(value.target.value))
            }
            style={{
              marginTop: "10px",
              backgroundColor: "white",
            }}
            error={errorUnitario !== ""}
            helperText={errorUnitario}
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
            tabindex="-1"
          >
            {`Fechar (${CANCELAR_VENDA_ACTION})`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSalvarLocal}
            tabindex="-1"
            disabled={isError}
          >
            {`Prosseguir (${FECHAR_VENDA_ACTION})`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogoCpfNota;
