import { createContext } from 'react';

export const DescarregoMercadoriaContext = createContext({
  descarrego: {
    idEdit: -1,
    fornecedor: '-',
    itens: [],
    parcelas: [],
    total: 0,
    valorUnitario: 0,
    peso: 0, 
    data: '-'
  },
  dispatch: () => {},
});
