import React, { useReducer, useEffect, useRef } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";

import { getListAllAPI, getSessionId, api, getUserId } from "../../services";
import { CLIENTE_DEFAULT, POR_UNIDADE, POR_PESO_FIXO, POR_PESO_VARIAVEL_SEM_UNIDADE, trunc10 } from "../../utils";
import InicioFrenteCaixaVenda from "../CreateVenda/_SubPages/Itens";
import FinalizarFrenteCaixaVenda from "../CreateVenda/_SubPages/Parcelas";
import { VendaContext } from "../CreateVenda/context";
import reducer from "../CreateVenda/reducer";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import DialogoEscolherVenda from './DialogoEscolherVenda'

function getTotal2(peso, unidades, precoUnitario, produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(unidades * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
      if (produto.nivelCaixa) {
        return trunc10(unidades * precoUnitario, -2);
      }
      return trunc10(peso * precoUnitario, -2);
    }
    return trunc10(peso * precoUnitario, -2);
  }
  return 0;
}

export function formatItens(itensOld) {
  const itensNew = [];
  for (let i = 0; i < itensOld.length; i += 1) {
    const unidades = itensOld[i].unidades_caixa_aparente
      ? itensOld[i].unidades_caixa_aparente
      : itensOld[i].unidades_caixa
      ? itensOld[i].unidades / itensOld[i].unidades_caixa
      : itensOld[i].unidades;
    const unitario = itensOld[i].unidades_caixa_aparente
      ? itensOld[i].preco_venda_caixa
      : itensOld[i].unidades_caixa
      ? itensOld[i].preco_venda_caixa
      : itensOld[i].preco_venda;

    const isNivel = itensOld[i].unidades_caixa_aparente
      ? true
      : !!itensOld[i].unidades_caixa;

    itensNew.push({
      produto: itensOld[i].produto,
      peso: itensOld[i].peso,
      total: getTotal2(itensOld[i].peso, unidades, unitario, {
        ...itensOld[i].produto,
        nivelCaixa: isNivel,
      }),
      unidades: itensOld[i].unidades,
      unitario: itensOld[i].preco_venda,
      uidd: `${itensOld[i].produto.nome}${itensNew.length}`,
      //id: itensOld[i].id,
      idIndicadorChange: itensOld[i].id,
      unidadesCaixa: itensOld[i].unidades_caixa,
      unitarioCaixa: itensOld[i].preco_venda_caixa,
      pesoCaixa: itensOld[i].peso_caixa,
      unidadesCaixaAparente: itensOld[i].unidades_caixa_aparente,
      nivelCaixaria: itensOld[i].nivel_caixaria,
      cgp: null,
      grupo_oferta_preco_produto_id: itensOld[i].grupo_oferta_preco_produto_id,
      tipo_oferta: itensOld[i].tipo_oferta,
      is_margem_cartao: itensOld[i].is_margem_cartao,
      dadosCgp: null
    });
  }
  return itensNew;
}

export function formatParcelas(parcelasOld) {
  const parcelasNew = [];

  for (let i = 0; i < parcelasOld.length; i += 1) {
    parcelasNew.push({
      data_pagamento: parcelasOld[i].data_pagamento,
      tipo_pagamento: parcelasOld[i].tipo_pagamento,
      valor:
        parcelasOld[i].tipo_pagamento_id === 1
          ? parcelasOld[i].valor_real
          : parcelasOld[i].valor,
      uidd: `${parcelasOld[i].tipo_pagamento.nome}${parcelasNew.length}`,
      troco: parcelasOld[i].troco,
      valor_recebido: parcelasOld[i].valor_recebido,
      idIndicadorChange: parcelasOld[i].id,
      //id: parcelasOld[i].id,
      autorizador_id: parcelasOld[i].autorizador,
      conta_id: parcelasOld[i].tipo_pagamento.id === 10 ? parcelasOld[i].tipo_pagamento.conta.id : null,
      conta: parcelasOld[i].tipo_pagamento.id === 10 ? parcelasOld[i].tipo_pagamento.conta : null
    });
  }
  return parcelasNew;
}

const tempoPing = 5 * 1000;

const CreateVenda = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [venda, dispatch] = useReducer(reducer, {
    cliente: null,
    itens: [],
    itensExcluidos: [],
    parcelas: [],
    idEdit: -1,
    isEditPrice: false,
    tipoVenda: 0,
    isEmPartes: false,
    uuid: null,
    idEdit: -1,
  });
  const { path } = useRouteMatch();
  const refDialogoEScolherVenda = useRef(0);

  if (getUserId() !== 1) {
    enqueueSnackbar("Sem permissão para essa área", {
      variant: "error",
    });
    history.push("/");
  }

  async function getDataResource(id) {
    try {
      const data = await getListAllAPI("vendas", ["id", "asc"], { id: [id] }, [
        "itens.produto.unidade",
        "itensExcluidos.produto.unidade",
        "parcelas.tipo_pagamento",
        "parcelas.autorizador",
        "parcelas.receita.conta",
        "cliente.cidade.estado",
        "cliente.categoria",
        "sessao.user",
        "sessao.caixa",
        "itensDevolucao",
      ]);
      if (data.data.length > 0) {
        dispatch({ 
          type: "PREENCHER_TUDO", 
          isEditPrice: false,
          idEdit: data.data[0].id,
          itens: formatItens(data.data[0].itens),
          parcelas: formatParcelas(data.data[0].parcelas),
          cliente: data.data[0].cliente,
          tipoVenda: 0,
          isEmPartes: data.data[0].is_em_partes,
          uuid: data.data[0].uuid,
          cpf: data.data[0].cpf,
          itensExcluidos: [],
        });
      } else {
        throw "Esta venda não existe!";
      }
    } catch (erros) {
      enqueueSnackbar(`${erros}`, {
        variant: "error",
      });
     // handleCloseDialog(-1);
    }
  }

  function irParaTelaInit() {
    history.push("/");
  }

  function irParaTelaFinalizarVenda() {
    history.push("/vendas_editar/finalizarvenda");
  }

  function irParaTelaFrentedeCaixa() {
    history.push("/vendas_editar/frentedecaixa");
  }

 /*  useEffect(() => {
    if(refDialogoEScolherVenda.current) {
      refDialogoEScolherVenda.current.handleOpen()
    }
  }, []) */

  return (
    <>
      {venda.cliente && venda.uuid ? (
        <VendaContext.Provider value={{ venda, dispatch }}>
          <Switch>
            <Route
              path={`${path}/frentedecaixa`}
              render={(props) => (
                <InicioFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFinalizarVenda={irParaTelaFinalizarVenda}
                />
              )}
            />
            <Route
              path={`${path}/finalizarvenda`}
              render={(props) => (
                <FinalizarFrenteCaixaVenda
                  irParaTelaInit={irParaTelaInit}
                  irParaTelaFrentedeCaixa={irParaTelaFrentedeCaixa}
                  getSessionId={() => getSessionId()}
                />
              )}
            />
          </Switch>
        </VendaContext.Provider>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontSize: "40px",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          Inicializando a Venda...
          <Button
            onClick={irParaTelaInit}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {`Voltar Para a Tela Inicial`}
          </Button>
          <Button
            onClick={() => {
              if(refDialogoEScolherVenda.current) {
                refDialogoEScolherVenda.current.handleOpen()
              }
            }}
            color="secondary"
            variant="contained"
            autoFocus
          >
            {`EScolher Venda`}
          </Button>
        </div>
      )}
      <DialogoEscolherVenda ref={refDialogoEScolherVenda} handleVendaId={getDataResource}/>
    </>
  );
};

export default CreateVenda;
